<template>
	<v-col class="mx-auto" cols="12" lg="11" xl="10" :class="$vuetify.breakpoint.width < 600 ? 'px-3':'px-5'">
		<v-row align="center">
			<v-col cols="auto" v-if="$vuetify.breakpoint.width >= 600">
				<div class="title-1">{{ label ? $options.filters.firstCapital(label) : $options.filters.firstCapital($route.meta.route_label) }}</div>
			</v-col>
			<v-divider vertical class="my-4 mr-2 ml-2" v-if="$vuetify.breakpoint.width >= 600"/>
			<v-col class="px-1">
				<v-row class="mx-0" no-gutters align="center">
					<v-col cols="auto">
						<v-row class="mx-0" align="center">
							<v-btn class="my-1" icon color="primary" small
							       @click="$route.path.match('dashboard') ? $router.push($store.getters.dashboard_url) : $router.push($store.getters.marketplace_url)">
								<v-icon small>{{ $route.path.match('dashboard') ? 'mdi-home' : 'mdi-shopping' }}</v-icon>
							</v-btn>

							<v-icon small class="ml-1 mr-2" v-show="items.lenght > 0 ? Object.keys(items[0])[0] === 'root' ? items.length > 1 : true : false">mdi-chevron-double-right</v-icon>

							<v-menu v-if="!$route.path.match('dashboard') && categories.length > 0" open-on-hover bottom offset-y rounded="lg">
								<template v-slot:activator="{ on, attrs }">
									<div
											v-on="on"
											class="caption-1 primary--text py-2"
											@click="category !== null ? toCategories(): ''"
									>
										{{ category !== null ? category.length === 1 ? $options.filters.firstCapital(category[0].display_name) : $tc('global.name.category', 2) : $tc('global.name.category', 2) }}
									</div>
								</template>

								<v-list style="max-height: 600px; overflow-y: auto;" >
									<v-list-item v-for="(cate, i) in categories" :key="i" v-if="category !== null ? category.length === 1 ? cate.id === category[0].id ? false : true : true : true" dense style="cursor: pointer;">
										<v-list-item-content @click="$router.push($store.getters.marketplace_url + '?category='+cate.id)" class="paragraph">{{ cate.name }}</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-row>
					</v-col>

					<template v-for="(item, i) in items" v-if="Object.values(item)[0].title !== 'root'">
						<v-col :key="i" v-if="Object.values(item)[0].items.length > 0" cols="auto">
							<v-menu open-on-hover offset-y rounded="lg">
								<template v-slot:activator="{on}">
									<div
											class="paragraph py-2 primary--text"
											v-on="on"
									>
										{{ $options.filters.firstCapital(Object.values(item)[0].title) }}
									</div>
								</template>
								<v-list dense style="min-width: 100px">
									<v-list-item
											v-for="(it, j) in Object.values(item)[0].items"
											:key="j"
											link
											@click="$router.push(it.link)"
											:class="$route.name === it.name ? 'secondary' : 'transparent'"
									>
										<div
												class="paragraph"
												:class="$route.name === it.name ? 'white--text' : 'black2white--text'"
										>
											{{ it.title }}
										</div>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>

						<v-col v-else cols="auto">
							<router-link class="paragraph py-2 primary--text router-link" :to="Object.values(item)[0].link" v-if="!Object.values(item)[0].disabled">
								{{ Object.values(item)[0].title }}
							</router-link>
						</v-col>
						<v-icon small class="mx-2" v-if="i !== items.length - 1">mdi-chevron-double-right</v-icon>
					</template>
				</v-row>
			</v-col>
			<v-col cols="12" v-if="$vuetify.breakpoint.width < 600" class="pt-0">
				<div class="sub-title-1">{{ $route.meta.route_label }}</div>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
	export default {
		name: "BreadCrumbs",
		props: {label: {}, pevents: {default:null}},
		data() {
			return {
				items: [],
				categories: [],
				category:null,
			}
		},
		computed: {
			sidebar_routes() {
				return this.$store.getters.sidebar_routes
			}
		},
		watch: {
			'$route'() {
				this.init()
				this.categories = []
				this.getCategories()
			}
		},
		methods: {
			getCategories(){
				if (this.$route.path.search('/marketplace/product/') > -1){
					this.$wsc.getList('product/category', {}, success =>{
						this.categories = success
					}, fail => {

					})
				}
			},
			toCategories(){
				let strg =""
				this.category.forEach((cate, i) => {
					if (i < this.category.length + 1){
						strg += 'category=' + cate.id + '&'
					}
					else {
						strg += 'category=' + cate.id
					}
				})
				let routeData = this.$router.resolve({path: this.$store.getters.marketplace_url + '?' + strg})
				window.open(routeData.href, '')
			},
			computeLink(endpoint) {
				let res = []

				if (endpoint) {
					for (let i of endpoint.split('/')) {
						if (i.match(':')) {
							let vat = i.replace(':', '')
							res.push(this.$route.params[vat])
						} else {
							res.push(i)
						}
					}
				}

				return '/dashboard/' + res.join('/')
			},
			computeItems(found, children, parent, callback) {
				let tmp = {}

				let parent_link = this.computeLink(parent.endpoint)

				tmp[parent.name] = {title: parent.label, name: parent.name, link: parent_link, items: []}

				for (let i = 0; i < children.length; i++) {
					let child = children[i]

					let child_link = this.computeLink(child.endpoint)

					if (!child.name.match('trash')) {
						tmp[parent.name].items.push({title: child.label, name: child.name, link: child_link, items: []})
					}

					if (child.name === found.name) {
						if (found.id === null && this.items.length === 0) {
							callback(tmp)
						}
						else if(found.id !== null) {
							let tmp2 = {}

							tmp2['id'] = {
								title: child.label,
								name: found.name,
								link: child_link,
								items: []
							}

							let tmp3 = {}

							tmp3['id'] = {
								title: found.id,
								name: found.name,
								link: child_link + '/' + found.id,
								items: []
							}

							if(this.$route.name === found.name){
								callback(tmp2)
							}
							else {
								callback(tmp2, tmp3)
							}
						}
					}
					else {
						this.computeItems(found, child.children, child, callback)
					}
				}
			},
			init() {
				this.items = []
				let array = this.$route.path.split('/').filter(e => e !== '' && e !== 'dashboard')
				let name = ''
				let found = {}

				for (let i of array) {
					if (isNaN(parseInt(i))) {
						name += i
						found = {name: name, id: null}
					} else {
						found = {name: name, id: parseInt(i)}
					}

					this.computeItems(found, this.sidebar_routes.children, this.sidebar_routes, (tmp, tmp2) => {
						if(this.items.length === 0){
							this.items.push(tmp)
						}
						else {
							if(!this.items.find(e => Object.keys(e)[0] === Object.keys(tmp)[0] && Object.keys(tmp)[0] !== 'id')){
								if(tmp2)
									this.items.push(tmp, tmp2)
								else
									this.items.push(tmp)
							}
						}
					})
				}
			},
			setUpHooks(){
				if (this.pevents) {
					this.pevents.wait('category', (cate) => {
						this.category = cate
					})
				}
			}
		},
		created() {
			this.init()
			this.getCategories()
			this.setUpHooks()
		}
	}
</script>

<style scoped>

</style>
